import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box } from 'grommet';
import { TextSection, ButtonBanner, ServicesL3Hero, CityworksPowerBlock } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allSupplier_diversitys {
        edges {
          node {
            intro_title
            intro_copy
            intro_image
            our_commitment_title
            commitment_title1
            commitment_copy1
            commitment_image1
            commitment_title2
            commitment_copy2
            commitment_image2
            key_goals_title
            key_goals_copy
            cta_banner
            cta_btn
            meta_title
            meta_description
            services {
              service_title
              service_text
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const services = content.services.map((item) => {
    return {
      name: item.service_title,
      text: item.service_text,
    };
  });
  const contactLink = () => (
    <a href="mailto:supplierdiversity@powereng.com">supplierdiversity@powereng.com</a>
  );

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.intro_image && content.intro_image.url}
    >
      <Box>
        <ServicesL3Hero
          headerText={content.intro_title}
          bodyText={content.intro_copy}
          image={content.intro_image}
          htmlSerializer={htmlSerializer}
          contactLink={contactLink}
        />
        {/* cityworks template was used for supplier diversity. */}
        <CityworksPowerBlock
          header1Text={content.our_commitment_title}
          header2Text={content.commitment_title1}
          image1={content.commitment_image1}
          body2Text={content.commitment_copy1[0].text}
          header3Text={content.commitment_title2}
          image2={content.commitment_image2}
          body3Text={content.commitment_copy2[0].text}
          htmlSerializer={htmlSerializer}
        />
        <TextSection
          heading={content.key_goals_title}
          body={content.key_goals_copy}
          textSectionCards={services}
          htmlSerializer={htmlSerializer}
        />
        <ButtonBanner
          Link={Link}
          heading={content.cta_banner}
          button={content.cta_btn}
          href="/supplier-registration"
        />
      </Box>
    </Layout>
  );
}
